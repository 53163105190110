import validator from "validator";
import { API } from "aws-amplify";
import DOMPurify from "dompurify";

export const sanitizeInput = (input) => {
  return DOMPurify.sanitize(input, {
    USE_PROFILES: { html: false, svg: false, mathMl: false }
  });
};

export const isValidName = (name) => {
  return name && name !== "" && /^[a-zA-Z\s]+$/.test(name);
};

export const isValidEmail = (email) => {
  return validator.isEmail(email);
};

export const isValidMobileNo = async (mobileNo) => {
  const sanitizedMobileNo = sanitizeInput(mobileNo);
  if (sanitizedMobileNo && sanitizedMobileNo !== "") {
    try {
      let isVerifiedMobileNo = await API.post("v2", `marketplace/accounts/verify-seller-phone`, {
        body: {
            phone_number: `+1${sanitizedMobileNo}`
        },
      });
      const isValidMobileNo = validator.isMobilePhone(sanitizedMobileNo, ["en-CA"]) && isVerifiedMobileNo;
      return isValidMobileNo;
    } catch (error) { }
  }
  return false;
};